import './App.css';
import Navbar from "./components/Navbar/Navbar"
import Home from "./components/Home/Home"
import Service from "./components/Service/Service"

function App() {
  return (
    <div className="App">
    
      <Navbar/>
      <Home/>
      <Service/>
     
   
    </div>
  );
}

export default App;
