import './Service.css';
import serviceImg1 from '../../img/kam.jpg';

function Service() {
  return (
    <section className="service-section" id="services">
      <div className="container">
        <div className="row">
          <div className="section-title">
            <h1>Service</h1>
          </div>
        </div>
        <div className="row">
          
          
          <div className="service-item">
            <div className="service-item-inner">
            <img src={serviceImg1} alt="service" />
              <div className="overlay">
                <h4>Media production</h4>
              </div>
            </div>
          </div>
          <div className="service-item">
            <div className="service-item-inner">
            <img src={serviceImg1} alt="service" />
              <div className="overlay">
                <h4>Media production</h4>
              </div>
            </div>
          </div>
          <div className="service-item">
            <div className="service-item-inner">
            <img src={serviceImg1} alt="service" />
              <div className="overlay">
                <h4>Media production</h4>
              </div>
            </div>
          </div>
          <div className="service-item">
            <div className="service-item-inner">
            <img src={serviceImg1} alt="service" />
              <div className="overlay">
                <h4>Media production</h4>
              </div>
            </div>
          </div>
          <div className="service-item">
            <div className="service-item-inner">
            <img src={serviceImg1} alt="service" />
              <div className="overlay">
                <h4>Media production</h4>
              </div>
            </div>
          </div>
          <div className="service-item">
            <div className="service-item-inner">
            <img src={serviceImg1} alt="service" />
              <div className="overlay">
                <h4>Media production</h4>
              </div>
            </div>
          </div>
          
          
        </div>
      </div>
    </section>
  );
}

export default Service;