import React, { useState, useEffect } from 'react';
import './Home.css';
import Img1 from '../../img/kam.jpg';
import Img2 from '../../img/kam2.jpg';

function Home() {
  const images = [
    Img1,
    Img2
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [images]);

  return (
    <section
      className="home-section"
      id="home"
      style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
    >
      <div className="shape-01"></div>
      <div className="container">
        <div className="row align-items-center">
          <div className="home-content">
            {/* Füge hier den Titel oder anderen Inhalt hinzu */}
            <h1>MEDIA PRODUCTION</h1>
          </div>
        </div>
      </div>
      {/* Scroll Down */}
      <a href="#services" className="scroll-down">
        <span className="scroll-down-image"></span>
      </a>
    </section>
  );
}

export default Home;



