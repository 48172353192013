import React, { useEffect, useState } from 'react';
import './Navbar.css';
import logo from '../../img/logo.png';

function Navbar() {
  const [isHeaderFixed, setHeaderFixed] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);

  // Event-Handler für den Scroll-Event
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setHeaderFixed(true);
    } else {
      setHeaderFixed(false);
    }
  };

  // Registriere den Scroll-Event-Listener beim Montieren des Komponenten
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Clean-up-Funktion, um den Event-Listener zu entfernen, wenn die Komponente entmontiert wird
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Event-Handler für den Klick auf den Nav-Toggle
  const handleNavToggle = () => {
    setNavOpen((prevState) => !prevState);
  };

  // Event-Handler für den Klick auf einen Navigationslink
  const handleNavItemClick = () => {
    if (window.innerWidth < 768) {
      setNavOpen(false);
    }
  };

  return (
    <header className={`header ${isHeaderFixed ? 'fixed' : ''}`}>
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="brand-name">
            <img className="logo" src={logo} alt="Logo" />
            <a className="text" href="index.html">EBDAA ALMARFA</a>
          </div>
          <div className="nav-toggle" onClick={handleNavToggle}>
            <span></span>
          </div>
          <nav className={`nav ${isHeaderFixed ? 'open' : ''} ${isNavOpen ? 'open' : ''}`}>
            <ul>
              <li><a href="#home" onClick={handleNavItemClick}>Home</a></li>
              <li><a href="#about" onClick={handleNavItemClick}>About</a></li>
              <li><a href="#services" onClick={handleNavItemClick}>Services</a></li>
              <li><a href="#works" onClick={handleNavItemClick}>Works</a></li>
              <li><a href="#contact" onClick={handleNavItemClick}>Contact</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Navbar;

